import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Link } from "react-router";

import SettingLine from "Components/SettingLine";
import ButtonWrapper from "Components/ButtonWrapper";
import Button from "Components/Button";
import InputField from "Components/fields/InputField";
import Description from "Components/fields/Description";

const Layout = styled.div`
  a {
    color: ${props => props.theme.links};
    text-decoration: none;
  }
  .actions {
    padding-top: 32px;
    justify-content: flex-start;
    .outline {
      position: relative;
    }
    .disable {
      position: absolute;
    }
    button + button {
      margin-left: 16px;
    }
  }
`;

const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  span:first-of-type {
    width: 50%;
  }
  span:nth-of-type(2) {
    width: 20%;
  }
`;

const FormWrapper = styled.div`
  padding: 24px 32px 32px;
  font-size: 14px;
  color: #38485e;
  .verification-code {
    margin-bottom: 0;
  }
`;

const LinksWrapper = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  a {
    line-height: 19px;
    margin-bottom: 4px;
  }
`;

const QRWrapper = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 24px;
  .qr-code {
    height: 285px;
    width: 285px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24x;
  }
`;

class OrganizationConnectedAccounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: true,
      enabledDate: "",
      browsers: [
        {
          browser: "Chrome",
          expires: "Wed, 2019-01-02 18:20"
        }
      ],
      trustedBrowsers: [
        {
          browser: "Chrome",
          expires: "Wed, 2019-01-02 18:20"
        }
      ]
    };
    this.enableTFA = this.enableTFA.bind(this);
  }

  enableTFA() {
    this.setState({
      enabled: true
    });
  }

  // TODO: Show authenticator code in the input field.
  // TODO: Display the QR code.
  // TODO: Set up verification code validation.

  render() {
    return (
      <Layout>
        <LinksWrapper>
          <Link
            to="https://support.google.com/accounts/answer/1066447?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Authenticator (Android/iPhone/BlackBerry)
          </Link>
          <Link
            to="https://www.authy.com/app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Authy (Android/iPhone)
          </Link>
          <Link
            to="http://www.windowsphone.com/en-us/store/app/authenticator/021dd79f-0598-e011-986b-78e7d1fa76f8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Authenticator (Windows Phone)
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=org.fedorahosted.freeotp"
            target="_blank"
            rel="noopener noreferrer"
          >
            FreeOTP (Android)
          </Link>
          <Link
            to="https://github.com/gbraad/gauth"
            target="_blank"
            rel="noopener noreferrer"
          >
            GAuth Authenticator (Firefox OS, desktop, others)
          </Link>
        </LinksWrapper>
        <SettingLine
          info={
            <InfoLayout className="account-info">
              <span>
                <strong>Install authentication code application</strong>
              </span>
            </InfoLayout>
          }
          noToggle={true}
          isOpen={true}
        >
          <FormWrapper>
            <InputField
              className="authenticator-code"
              label="Authenticator text code"
              required={false}
            />
            <Description>
              Enter this code into your two-factor authentication app or scan
              the QR code below.
            </Description>
            <QRWrapper>QR Code</QRWrapper>
            <InputField
              className="verification-code"
              label="Verification code"
            />
            <ButtonWrapper className="actions">
              <Button>Verify & Save</Button>
              <Button className="secondary">Cancel</Button>
            </ButtonWrapper>
          </FormWrapper>
        </SettingLine>
      </Layout>
    );
  }
}

OrganizationConnectedAccounts.propTypes = {
  title: PropTypes.string,
  closeForm: PropTypes.func,
  errors: PropTypes.object,
  onSave: PropTypes.func,
  intl: PropTypes.object
};

export default injectIntl(OrganizationConnectedAccounts);
<Layout>Connected accounts content goes here.</Layout>;
