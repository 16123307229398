import React from "react";
import PropTypes from "prop-types";

import ContentLayout from "Components/ContentLayout";
import Heading2 from "Components/styleguide/Heading2";
import PageDescription from "Components/PageDescription";
import OrganizationSecuritySetup from "Components/OrganizationSecuritySetup";
import BackLink from "Components/BackLink";

class OrganizationSecuritySetupPage extends React.Component {
  render() {
    return (
      <ContentLayout id="settings-members" className="settings setting-return">
        <BackLink
          to={`/${this.props.params.organizationId}/settings/security`}
        />
        <Heading2>TFA application setup</Heading2>
        <PageDescription>
          The two-factor authentication application will be used during this
          setup and for generating codes during regular authentication. If the
          application supports it, scan the QR code below to get the setup code
          otherwise you can manually enter the text code.
        </PageDescription>
        <OrganizationSecuritySetup {...this.props.params} />
      </ContentLayout>
    );
  }
}

OrganizationSecuritySetupPage.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  params: PropTypes.shape({
    organizationId: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};
export default OrganizationSecuritySetupPage;
