import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { Link } from "react-router";

import Back from "Icons/Back";

const BackLinkWrapper = styled.div`
  a {
    font-size: 13px;
    color: #4b6180;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-decoration: none;
    svg {
      margin-right: 12px;
    }
  }
  margin-bottom: 16px;
`;

class BackLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { intl, to } = this.props;

    return (
      <BackLinkWrapper>
        <Link to={to}>
          <Back /> {intl.formatMessage({ id: "back" })}
        </Link>
      </BackLinkWrapper>
    );
  }
}

BackLink.propTypes = {
  intl: PropTypes.object,
  to: PropTypes.string
};

export default injectIntl(BackLink);
